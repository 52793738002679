<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="moudForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('moudForm')"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="moudForm" :model="moudForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_16">
        <el-col :md="8">
          <el-form-item label="申请单号" prop="moud_no">
            <el-input
              maxlength="10"
              v-model="moudForm.moud_no"
              @input="moudForm.moud_no = helper.keepEngNum2(moudForm.moud_no)"
              placeholder="请填写申请单号"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司编号" prop="cptt_no">
            <el-select v-model="moudForm.cptt_no" placeholder="请选择公司编号" @change="cpttChange" size="small" clearable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_no" :value="item.cptt_no"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="总金额" prop="moud_price">
            <el-input
              maxlength="10"
              v-model="moudForm.moud_price"
              @blur="moudForm.moud_price = helper.calcPriceOne(moudForm.moud_price, 4, 1000000)"
              @input="moudForm.moud_price = helper.keepTNum1(moudForm.moud_price)"
              placeholder="将根据额外金额和物料价格自动生成"
              show-word-limit
              :disabled="true"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_16">
        <el-col :md="8">
          <el-form-item label="交货地址" prop="cptt_address">
            <el-input maxlength="50" v-model="moudForm.cptt_address" type="textarea" rows="1" placeholder="请填写地址" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交货时间" prop="moud_deli_time">
            <el-date-picker v-model="moudForm.moud_deli_time" type="date" placeholder="请选择时间日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="额外金额" prop="extra_price">
            <el-input
              maxlength="10"
              v-model="moudForm.extra_price"
              @blur="moudForm.extra_price = calcPriceOne(moudForm.extra_price, 4, 1000000)"
              @input="moudForm.extra_price = helper.keepTNum1(moudForm.extra_price)"
              placeholder="将根据零售价格和生产价格自动生成"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="备注">
            <el-input maxlength="200" v-model="moudForm.remark" type="textarea" rows="1" placeholder="请填写备注" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>

      <div>
        <div class="vd_but">
          <!-- <el-button type="primary" plain size="small" @click="addRow()">新增</el-button> -->
          <el-button type="success" plain size="small" @click="smplImport()">导入</el-button>
          <el-button type="danger" plain size="small" @click="delBom()">删除</el-button>
        </div>
        <MoudSmplList
          @sourPrice="sourPrice"
          :mtrlTypeGroupOpt="mtrlTypeGroupOpt"
          :moudForm="moudForm"
          :stffType="stffType"
          @handleSelectionChange="handleSelectionChange"
        ></MoudSmplList>
      </div>

      <el-dialog :title="title" :visible.sync="smplFlag" width="70%">
        <MoudMdrlImport
          @falseBom="falseBom"
          @mdrlConfirm="mdrlConfirm"
          @childSmplChanel="childSmplChanel"
          ref="SmplEditIn"
        ></MoudMdrlImport>
      </el-dialog>

      <!--      <el-dialog :title="title" :visible.sync="mdrlFlag" width="70%">-->
      <!--        <MoudMdrlImport @falseMtrl="falseMtrl" @mdrlChanel="mdrlFlag" @mdrlConfirm="mdrlConfirm" ref="MoudMdrlImport"/>-->
      <!--      </el-dialog>-->

      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="moudForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
import { cpttAPI } from '@api/modules/cptt';
import { optnAPI } from '@api/modules/optn';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import MoudSmplList from '@/views/PurchaseManagement/MoudManage/TabChild/Componet/MoudSmplList.vue';
import MoudMdrlImport from '@/views/PurchaseManagement/MoudManage/TabChild/Componet/MoudMdrlImport.vue';
import { moudAPI } from '@api/modules/moud';
import { cloneDeep } from 'lodash';

export default {
  name: 'moudEditMain',
  components: {
    editHeader,
    inputUser,
    MoudSmplList,
    MoudMdrlImport
  },
  data() {
    var validatePrice = (rule, value, callback) => {
      if (value > 999999.9999) {
        callback(new Error('总金额不能大于999999.9999'));
      }
      callback();
    };
    return {
      rules: {
        sour_price: [{ validator: validatePrice }],
        sour_no: [{ required: true, trigger: 'blur', message: ' ' }],
        cptt_id: [{ required: true, trigger: 'change', message: ' ' }],
        cptt_address: [{ required: true, trigger: 'change', message: ' ' }],
        sour_deli_time: [{ required: true, trigger: 'change', message: ' ' }],
        extra_price: [
          {
            pattern: /^\d{0,4}(\.\d{1,4})?$/,
            message: '最大只能输入9999.9999并且只能输入数字且最多4位小数!'
          }
        ]
      },
      moudForm: {},
      isShow: true,
      btn: {},
      stffForm: {},
      stffType: [],
      selectChangeList: [],
      mtrlTypeGroupOpt: [],
      cpttList: [],
      smplFlag: false,
      mdrlFlag: false,
      title: '物料信息',
      totalPrice: null,
      loadingFlag: true,
      moudCopy: []
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.getsourInfo();
      this.getStffType();
      this.getCpttV1();
      this.getMtrlType();
      this.loadingFlag = true;
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.saveInfo();
          } else {
            console.log('error submit!');
            return false;
          }
        });
      });
    },
    // 保存
    saveInfo() {
      let moudForm = {};
      moudForm = Object.assign(moudForm, this.moudForm);
      console.log('moub_form', this.moudForm);
      moudForm.moud_deli_time = parseInt(Number(moudForm.moud_deli_time) / 1000);
      console.error(333, moudForm.moud_mtrl_list);
      moudForm.moud_mtrl_list = Object.assign(moudForm.moud_mtrl_list, this.moudCopy);
      console.log(222, moudForm);
      post(moudAPI.editmoud, moudForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
        } else if (res.data.code === 7) {
          this.$message({
            type: 'error',
            message: '申请单号重复'
          });
          moudForm.moud_deli_time = Number(moudForm.moud_deli_time) * 1000;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
          moudForm.moud_deli_time = Number(moudForm.moud_deli_time) * 1000;
        }
      });
    },

    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('sourForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 计算价格
    calcPriceOne(num, posi = 0, str) {
      let num1 = 0;
      let fre = Math.pow(10, posi);
      num1 = Math.floor(num * fre) / fre;
      if (isNaN(num1)) {
        return 0;
      } else {
        if (num1 >= str) {
          this.$message({
            type: 'error',
            message: '总金额不能大于1000000'
          });
          return '';
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.0000';
            } else {
              if (value.length - 1 - ind > 4) {
                return value.splice(ind + 5);
              } else if (value.length - 1 - ind < 4) {
                let index = 4 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // cptt变化选择地址编号
    cpttChange(val) {
      let temp = {};
      temp = this.cpttList.filter(item => item.cptt_id === val);
      if (temp.length === 1) {
        this.moudForm.cptt_address = temp[0].cptt_address;
        this.moudForm.cptt_no = temp[0].cptt_no;
      }
    },
    // 打开弹框
    smplImport() {
      this.smplFlag = true;
    },
    // 导入传值
    mdrlConfirm(val) {
      let find = this.moudForm.moud_mtrl_list.some(x => x.mdrl_id === val.mdrl_id);
      if (find) {
        this.$message({
          message: '禁止添加相同的物料信息',
          type: 'warning'
        });
      } else {
        let temp = cloneDeep(val);
        temp.mtrl_num = null;
        this.moudForm.moud_mtrl_list.push(temp);
        this.emptyDisable = false;
        this.smplFlag = false;
      }
    },
    // 取消选择
    childSmplChanel() {
      this.smplFlag = true;
    },
    falseBom() {
      this.childSmplChanel();
    },
    // 样品list清单多选删除
    delBom() {
      if (this.selectChangeList.length > 0) {
        this.moudForm.moud_mtrl_list = this.moudForm.moud_mtrl_list.filter(item =>
          this.selectChangeList.every(item1 => item.mdrl_id !== item1.mdrl_id)
        );
        for (let i = 0; i < this.selectChangeList.length; i++) {
          for (let j = 0; j < this.moudCopy.length; j++) {
            if (this.selectChangeList[i].mdrl_id === this.moudCopy[j].mdrl_id) {
              this.moudCopy[j].destroy_flag = 1;
            }
          }
        }
        let price = 0.0; //总金额
        let price1 = 0.0; //额外金额
        console.log(555555555, this.moudForm.moud_mtrl_list);
        let mdrlList = this.moudForm.moud_mtrl_list;
        for (let i = 0; i < mdrlList.length; i++) {
          if (mdrlList[i].mtrl_num && mdrlList[i].no_mdrl_price) {
            price += (parseInt(Number(mdrlList[i].no_mdrl_price) * 10000) * parseInt(Number(mdrlList[i].mtrl_num) * 10000)) / 10000 / 10000;
            price1 +=
              (parseInt(Number(mdrlList[i].no_mdrl_price - mdrlList[i].mdrl_price) * 10000) *
                parseInt(Number(mdrlList[i].mtrl_num) * 10000)) /
              10000 /
              10000;
          }
        }
        price = parseInt(Number(price) * 1000000);
        price1 = parseInt(Number(price1) * 1000000);

        this.moudForm.moud_price = price / 1000000;
        this.moudForm.extra_price = price1 / 1000000;
        console.error(this.moudForm);
        if (this.moudForm.moud_mtrl_list.length === 0) {
          this.totalPrice = 0;
        }
      } else {
        this.$message.warning('至少选择一条信息');
      }
    },
    // 样品单list选择
    handleSelectionChange(val) {
      this.selectChangeList = val;
    },
    // 更改价格
    sourPrice(val, val2) {
      let price = parseInt(Number(val) * 1000000);
      let price1 = parseInt(Number(val2) * 1000000);
      //let price2 = parseInt(Number(this.moudForm.extra_price) * 1000000)
      this.moudForm.moud_price = price / 1000000;
      this.moudForm.extra_price = price1 / 1000000;
      this.totalPrice = JSON.parse(JSON.stringify(val));
    },
    // 获取委托人
    getStffType() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffType = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取公司信息
    getCpttV1() {
      get(cpttAPI.getAllCpttsV2)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },

    // 获取表单信息
    getsourInfo() {
      this.moudForm.moud_id = this.$route.query.form_id;
      getNoCatch(moudAPI.getMoudById, { moud_id: this.moudForm.moud_id }).then(({ data }) => {
        this.moudForm = data.form;
        this.moudForm.moud_deli_time = Number(this.moudForm.moud_deli_time) * 1000;
        this.moudCopy = JSON.parse(JSON.stringify(this.moudForm.moud_mtrl_list));
        this.btn = data.btn;
        this.moudForm.mdrl_price = this.helper.haveFour(this.moudForm.mdrl_price);
        this.moudForm.extra_price = this.helper.haveFour(this.moudForm.extra_price);
        this.stffForm.stff_name = this.moudForm.stff_name;
        this.stffForm.dept_name = this.moudForm.dept_name;
        this.stffForm.dept_team_name = this.moudForm.dept_team_name;
        this.stffForm.user_id = this.moudForm.user_id;
        this.stffForm.dept_id = this.moudForm.dept_id;
        this.stffForm.stff_id = this.moudForm.stff_id;
        this.totalPrice = data.form.moudForm - data.form.extra_price;
        setTimeout(() => {
          this.loadingFlag = false;
        }, 500);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_solidDiv {
  height: 1px;
  border: none;
  background-color: #dcdfe6;
  margin: 10px 0 20px 0;
}

.vd_but {
  margin-bottom: 10px;
}

.el-row {
  margin-top: 2px;
}
</style>
