<template>
  <div class="wrapper">
    <el-row :gutter="20">
      <el-col :md="8">
        <el-card>
          <appr-next
            :apprNext="apprNext"
            :isShowBTN="isShowBTN"
            :isEffect="isEffect"
            :isShow="isShow"
            :isShowEffect="isShowEffect"
            :isIneffective="isIneffective"
            :loadFlag="loadFlag"
            @getApprObj="getApprObj"
            @showEffect="showEffect"
            v-if="apprNext"
          ></appr-next>
          <div v-else>
            <i class="el-icon-info"></i>
            <span class="vg_ml_8">{{ nextContect }}</span>
          </div>
        </el-card>
      </el-col>
      <el-col :md="16">
        <appr-list :apprList="apprList"></appr-list>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import { apprAPI } from '@/api/modules/appr';
import apprNext from '@/views/component/apprCommon/apprNext';
import apprList from '@/views/component/apprCommon/apprList';

export default {
  name: 'apprMain',
  components: {
    apprList,
    apprNext
  },
  props: {
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      apprList: [],
      apprNext: {},
      nextContect: '',
      isShowBTN: false,
      isEffect: false,
      isShowEffect: false,
      isIneffective: false,
      loadFlag: true
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getApprObj();
    },
    // 反生效清除按钮
    showEffect(val) {
      this.isShowEffect = false;
      this.isEffect = false;
      this.$nextTick(() => {
        this.isShowEffect = val;
      });
    },
    // 获取所有信息
    getApprObj() {
      this.$emit('draftState');
      get(apprAPI.getApprInsts, {
        perm_id: this.$route.query.perm_id,
        form_id: this.$route.query.form_id,
        acct_id: null
      })
        .then(res => {
          if (res.data.code === 0) {
            this.apprList = res.data.data.list;
            this.apprNext = res.data.data.next;
            this.loadFlag = false;
            if (this.apprNext) {
              if (this.apprNext.appr_inst_this_node === -1) {
                this.isShowBTN = true;
              } else if (this.apprNext.appr_inst_this_node === 0) {
                this.isShowBTN = false;
                this.isEffect = true;
              } else {
                this.isShowBTN = false;
              }
            } else {
              this.nextContect = '暂无审批';
            }
          } else if (res.data.code === 2) {
            this.apprList = res.data.data.list;
            this.isEffect = true;
            this.loadFlag = false;
            if (res.data.data.status === 0) {
              this.isShowEffect = true;
            } else if (res.data.data.status === 2) {
              this.isShowEffect = false;
              if (res.data.data.btn === true) {
                this.isIneffective = true;
              } else {
                this.isIneffective = false;
              }
            }
          } else {
            this.loadFlag = false;
            let mg = res.data.msg;
            let err = 'error';
            this.$message({ type: err, message: mg });
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper .el-row {
  padding: 20px 80px;
}
.el-icon-info {
  color: $color-info;
}
</style>
