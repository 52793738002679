<template>
  <div class="wrapper-box">
    <div class="vd_head_button" v-if="isShowBTN">
      <el-button @click="subAppr()" type="success" :disabled="btnAble" size="small" :loading="loadFlag">提交审批</el-button>
    </div>
    <div class="vd_head_button" v-if="!isShowBTN && !isEffect">
      <el-button size="small" type="success" @click="passAppr()" :disabled="btnAble" :loading="loadFlag">通过</el-button>
      <el-button size="small" type="danger" @click="redAppr()" :disabled="btnAble" :loading="loadFlag">拒绝</el-button>
    </div>
    <div class="vd_head_button" v-if="isEffect">
      <div class="vd_head_button" v-if="isShowEffect">
        <el-button size="small" type="warning" @click="effectAppr()">生效</el-button>
      </div>
      <div class="vd_head_button" v-if="!isShowEffect">
        <div class="vd_head_button" v-if="isIneffective">
          <el-button size="small" type="danger" @click="ineffectiveAppr()">反生效</el-button>
        </div>
        <div v-if="!isIneffective">此单据已生效</div>
      </div>
    </div>
    <div class="vd_select_plane" v-if="showPanle">
      <el-card class="vd_textarea" shadow="none">
        <div v-if="purchaseFlag === 0">
          <span> ({{ this.btnCont }})备注：</span>
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
        </div>
        <div v-if="purchaseFlag === 1">
          <el-row v-if="btnCont === '通过'">
            <el-col :md="24">
              <span> ({{ this.btnCont }})备注：</span>
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
            </el-col>
          </el-row>
          <el-row v-if="btnCont === '拒绝'">
            <el-col :md="24">
              <span> 拒绝原因：</span>
              <el-select size="small" v-model="purchaseChoose" multiple placeholder="请选择">
                <el-option v-for="item in purchaeList" :key="item.id" :label="item.reason" :value="item.reason"> </el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card shadow="none">
        <div v-if="isSelPanel">
          <span>选择审批人：</span>
          <el-checkbox-group v-model="checkStffIds">
            <el-checkbox v-for="item in checkList" :label="item.user_id" :key="item.user_id"
              >{{ item.stff_name }}({{ item.dept_name }})</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div v-if="!isSelPanel">
          <i class="el-icon-warning"></i>
          <span class="vd_msg">{{ this.msgContent }}</span>
        </div>
        <div class="vg_button">
          <el-button size="small" @click="closePanel()">取消</el-button>
          <el-button size="small" type="primary" @click="submitPa(checkStffIds)">确认</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { apprAPI } from '@api/modules/appr';
// import webSocket from '@/plugin/webSocket'

export default {
  name: 'apprNext',
  props: {
    apprNext: Object,
    isShowBTN: Boolean,
    isEffect: Boolean,
    isIneffective: Boolean,
    isShowEffect: Boolean,
    nextContect: String,
    isShow: Boolean,
    loadFlag: Boolean
  },
  data() {
    return {
      checkStffIds: [],
      stffNameList: [],
      textarea: '',
      showPanle: false,
      btnAble: false,
      btnCont: '',
      apprFormB: {}, // 提交获取的信息
      apprForm: {},
      checkList: [],
      msgContent: '',
      isSelPanel: true,
      purchaseChoose: [],
      purchaseFlag: 0,
      purchaeList: [
        { id: 0, reason: '尺寸有误' },
        { id: 1, reason: '造型不到位' },
        { id: 2, reason: '产品面料/配件做错' },
        { id: 3, reason: '印刷/电绣有误' },
        { id: 4, reason: '做工复杂，需要简化' },
        { id: 5, reason: '其他' }
      ],
      heartbeatTimer: null
    };
  },
  created() {
    if (Number(this.$route.query.perm_id) === 112 && this.$cookies.get('userInfo').dept_name === '采购一部') {
      this.purchaseFlag = 1;
    }
  },
  mounted() {},
  methods: {
    // 提交
    subAppr() {
      console.log('this.purchaseFlag', this.purchaseFlag);
      this.showPanle = true;
      this.btnAble = true;
      this.apprForm = this.apprNext;
      this.apprForm.appr_inst_this_node = -1;
      this.apprForm.appr_inst_action = 1;
      this.btnCont = '提交';
      this.purchaseFlag = 0;
      this.getStffList();
    },
    // 通过
    passAppr() {
      if (Number(this.$route.query.perm_id) === 112 && this.$cookies.get('userInfo').dept_name === '采购一部') {
        this.purchaseFlag = 1;
      } else {
        this.purchaseFlag = 0;
      }
      this.showPanle = true;
      this.btnAble = true;
      this.apprForm = this.apprNext;
      this.apprForm.appr_inst_action = 2;
      this.btnCont = '通过';
      this.getStffList();
    },
    // 拒绝
    redAppr() {
      if (Number(this.$route.query.perm_id) === 112 && this.$cookies.get('userInfo').dept_name === '采购一部') {
        this.purchaseFlag = 1;
      } else {
        this.purchaseFlag = 0;
      }
      this.showPanle = true;
      this.btnAble = true;
      this.apprForm = this.apprNext;
      this.btnCont = '拒绝';
      this.apprForm.appr_inst_action = 3;
      this.getStffList();
    },
    // 生效按钮
    effectAppr() {
      if (this.isShow) {
        this.$confirm('请确定是否生效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.effect();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消生效'
            });
          });
      } else {
        this.$message({
          type: 'warning',
          message: '单据信息编辑未完成'
        });
      }
    },
    // 反生效按钮
    ineffectiveAppr() {
      if (this.isShow) {
        this.$confirm('请确定是否反生效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.ineffective();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消反生效'
            });
          });
      } else {
        this.$message({
          type: 'warning',
          message: '单据信息编辑未完成'
        });
      }
    },
    // 获取审批人
    getStffList() {
      const nodeBody = {};
      nodeBody.perm_id = this.apprForm.perm_id;
      nodeBody.form_id = this.apprForm.form_id;
      nodeBody.appr_inst_this_node = this.apprForm.appr_inst_this_node;
      nodeBody.appr_inst_action = this.apprForm.appr_inst_action;
      get(apprAPI.getApprInstNextNode, nodeBody)
        .then(res => {
          if (res.data.code === 0) {
            this.apprFormB = res.data.data;
            this.checkList = this.apprFormB.appr_node_user_list;
            if (this.apprFormB.appr_node_id === -1) {
              this.isSelPanel = false;
              this.msgContent = '确认后单据将退回到草拟状态!';
            } else if (this.apprFormB.appr_node_id === 0) {
              this.isSelPanel = false;
              this.msgContent = '确认后单据将变成为生效状态!';
            } else {
              this.isSelPanel = true;
            }
          } else {
            let mg = res.data.msg;
            this.isSelPanel = false;
            this.msgContent = mg;
          }
        })
        .catch(() => {});
    },
    // 关闭面板
    closePanel() {
      this.btnAble = false;
      this.showPanle = false;
    },
    // 提交选择人
    submitPa(checkStffIds) {
      if (this.isSelPanel) {
        if (checkStffIds.length === 0) {
          this.$message({ type: 'warning', message: '请至少勾选一个审核人员！' });
        } else {
          this.saveInfo();
        }
      } else {
        this.saveInfo();
      }
    },
    // 保存选择人挂api
    saveInfo() {
      if (this.purchaseFlag === 1) {
        if (this.btnCont === '通过') {
          this.apprForm.appr_inst_remark = this.textarea;
        } else if (this.btnCont === '拒绝') {
          this.apprForm.appr_inst_remark = this.purchaseChoose.join(',');
        }
      } else {
        this.apprForm.appr_inst_remark = this.textarea;
      }
      // this.apprForm.appr_inst_remark = this.textarea
      this.apprForm.next_user_id_list = this.checkStffIds;
      this.apprForm.appr_inst_next_node = this.apprFormB.appr_node_id;
      post(apprAPI.Approve, this.apprForm)
        .then(res => {
          if (res.data.code === 0) {
            console.log('this.apprForm', JSON.parse(JSON.stringify(this.apprForm)));
            let str = JSON.parse(JSON.stringify(this.checkStffIds.join('&')));
            let str1 = JSON.parse(JSON.stringify(this.apprForm.appr_inst_applier));
            this.$emit('getApprObj');
            this.btnAble = false;
            this.showPanle = false;
            this.$EventBus.$emit('submit', 12345);
            this.sendWeb(str, str1);
            this.checkStffIds = [];
            if (Number(this.$route.query.perm_id) === 112 && this.$cookies.get('userInfo').dept_name === '采购一部') {
              this.purchaseFlag = 1;
            } else {
              this.purchaseFlag = 0;
            }
          } else {
            let msg = res.data.msg;
            let q = 'error';
            this.$message({ type: q, message: msg });
          }
        })
        .catch(() => {});
    },
    sendWeb(val, val1) {
      if (this.isSelPanel) {
        let str = `提交1;${val1}#${val}`;
        this.$socket.send(str);
      } else {
        let str = `提交2;${val1}#${val}`;
        this.$socket.send(str);
      }
    },
    // 直接生效
    effect() {
      const nodeBody = {};
      nodeBody.perm_id = this.$route.query.perm_id;
      nodeBody.form_id = this.$route.query.form_id;
      post(apprAPI.makeEffective, nodeBody)
        .then(res => {
          if (res.data.code === 0) {
            this.$emit('getApprObj');
            this.$emit('flag', this.flag);
            this.$message.success(res.data.msg);
            this.btnAble = false;
            this.showPanle = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 反生效
    ineffective() {
      const nodeBody = {};
      nodeBody.perm_id = this.$route.query.perm_id;
      nodeBody.form_id = this.$route.query.form_id;
      post(apprAPI.makeIneffective, nodeBody)
        .then(res => {
          if (res.data.code === 0) {
            this.$emit('showEffect', true);
            this.$emit('getApprObj');
            this.btnAble = false;
            this.showPanle = false;
            this.isSelPanel = true;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
//.wrapper ::v-deep .el-card{
//  color:#969696
//}
.wrapper-box {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.vd_textarea {
  margin-bottom: 5px;
  .el-textarea {
    margin-top: 5px;
  }
}
.vd_select_plane {
  height: 100%;
  margin-top: 5px;
  max-width: 400px;
  min-height: 200px;
  position: relative;
  animation: mymove 0.2s forwards;
  animation-delay: 0.2s;
  .el-checkbox-group {
    margin-top: 5px;
  }
}
@keyframes mymove {
  from {
    top: 0px;
  }
  to {
    top: 20px;
  }
}

.vg_button {
  display: flex;
  justify-content: flex-end;
}
.vd_button {
  display: flex;
  justify-content: right;
}
.el-icon-warning {
  color: $color-deep-red;
}
.vd_msg {
  margin-left: 5px;
  font-weight: 700;
}
</style>
